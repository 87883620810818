import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useUser  } from '../context/UserContext';
import '../styles/PostPage.css';
import Modal from 'react-modal'; // Importuj Modal
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faComment } from '@fortawesome/free-solid-svg-icons';
import timeAgo from './inne/timeAgo';
import { handleUnlikePost, handleLikePost } from './inne/likePosts';

const PostPage = () => {
    const { uuid } = useParams();
    const { user } = useUser ();
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [error, setError] = useState('');
    const [reportDescription, setReportDescription] = useState('');
    const [reportCategory, setReportCategory] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // Stan dla modala
    const [loading, setLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [posts, setPosts] = useState([])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 500);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Sprawdź rozmiar na początku

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`https://gymsocial.pl:3001/api/posts/uuid/${uuid}`);
                setPost(response.data);
            } catch (err) {
                console.error('Błąd podczas pobierania posta:', err);
                setError('Nie udało się pobrać posta.');
            } finally {
                setLoading(false);
            }
        };

        const fetchComments = async () => {
            try {
                const postResponse = await axios.get(`https://gymsocial.pl:3001/api/posts/uuid/${uuid}`);
                const postId = postResponse.data.id;

                const commentsResponse = await axios.get(`https://gymsocial.pl:3001/api/posts/${postId}/comments`);
                setComments(commentsResponse.data);
            } catch (err) {
                console.error('Błąd podczas pobierania komentarzy:', err);
                setError('Nie udało się pobrać komentarzy.');
            }
        };

        fetchPost();
        fetchComments();
    }, [uuid]);

    const handleReportPost = async () => {
        try {
            await axios.post(`https://gymsocial.pl:3001/api/posts/uuid/${post.uuid}/report`, {
                userId: user.uid,
                description: reportDescription,
                category: reportCategory,
            });
            alert('Post został zgłoszony.');
            setReportDescription('');
            setReportCategory('');
            setIsModalOpen(false); // Zamknij modal po zgłoszeniu
        } catch (error) {
            console.error('Błąd podczas zgłaszania posta:', error);
            alert('Wystąpił błąd podczas zgłaszania posta.');
        }
    };

    const handleAddComment = async (e) => {
        e.preventDefault();
        if (!newComment.trim()) return;

        try {
            const postResponse = await axios.get(`https://gymsocial.pl:3001/api/posts/uuid/${uuid}`);
            const postId = postResponse.data.id;

            await axios.post(`https://gymsocial.pl:3001/api/posts/${postId}/comment`, {
                content: newComment,
                userId: user.uid,
            });
            setNewComment('');
            const commentsResponse = await axios.get(`https://gymsocial.pl:3001/api/posts/${postId}/comments`);
            setComments(commentsResponse.data);
        } catch (err) {
            console.error('Błąd podczas dodawania komentarza:', err);
            setError('Nie udało się dodać komentarza.');
        }
    };

    if (!post) {
        return <p>Ładowanie posta...</p>;
    }
    if (loading) {
        return <p>Ładowanie posta...</p>;
    }

    return (
        <div className="post-page">
            <div className="post-page-content-over">
                <div className="post-page-content">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {post.profilePic ? (
                            <img
                                src={`https://gymsocial.pl:3001/profilePic/${post.profilePic}`}
                                alt={`${post.nick}'s profile`}
                                style={{ width: ' 50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
                            />
                        ) : (
                            <img
                                src={`https://gymsocial.pl:3001/profilePic/d-p-f.png`}
                                style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }} />
                        )}
                        <Link to={`/profile/${post.userId}`} style={{ textDecoration: 'none', color: 'inherit', fontSize: '1.2em' }}>
                            <strong>{post.nick}</strong>
                        </Link>
                        <em style={{ marginLeft: '10px', fontSize: '0.9em', color: '#888' }}>{timeAgo(post.createdAt)}</em>
                        <button className='post-page-submit-report'onClick={() => setIsModalOpen(true)}>
                            Zgłoś post
                        </button>
                    </div>
                    <h1>{post.title}</h1>
                    {post.imageUrl && (
                        <img src={`https://gymsocial.pl:3001/postsPic/${post.imageUrl}`} alt={post.title} className="post-page-image" />
                    )}
                    <p className="post-page-text">{post.content}</p>
                    <div className="post-page-actions">
                        <div className='post-page-reaction-buttons'>
                            <button className='post-page-button' onClick={post.liked ? () => handleUnlikePost(post.id, user.uid, setPosts) : () => handleLikePost(post.id, user.uid, setPosts)}>
                                <FontAwesomeIcon icon={faHeart} style={{ color: post.liked ? 'red' : 'black' }} />
                            </button>
                            <span>{post.likesCount} {!isMobile && 'polubień'}</span>
                            <button className='post-page-button'>
                                <FontAwesomeIcon icon={faComment} style={{ color: 'blue' }} />
                            </button>
                            <span>{post.comments?.length || 0} {!isMobile && 'komentarzy'}</span>
                        </div>
                    </div>
                    <div className="post-page-comments-section">
                        <h2>Komentarze</h2>
                        <form onSubmit={handleAddComment} className="post-page-comment-form">
                            <textarea 
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                placeholder="Dodaj komentarz..."
                                className="post-page-comment-form-textarea"
                            />
                            <button type="submit">Wyślij</button>
                            {error && <p className="post-page-error-message">{error}</p>}
                        </form>
                        <ul className="post-page-comments-list">
                            {comments.map((comment) => (
                                <li key={comment.id} className="post-page-comment-item">
                                    <strong>{comment.userNick}:</strong> {comment.content}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {/* Modal do zgłaszania posta */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Zgłoś post"
                className="modal"
                overlayClassName="overlay"
            >
                <h2>Zgłoś post</h2>
                <form onSubmit={(e) => { e.preventDefault(); handleReportPost(); }}>
                    <div>
                        <label htmlFor="reportCategory">Kategoria:</label>
                        <select
                            id="reportCategory"
                            value={reportCategory}
                            onChange={(e) => setReportCategory(e.target.value)}
                            required
                        >
                            <option value="">Wybierz kategorię</option>
                            <option value="Spam">Spam</option>
                            <option value="Niewłaściwe treści">Niewłaściwe treści</option>
                            <option value="Zagrożenie dla zdrowia">Zagrożenie dla zdrowia</option>
                            <option value="Zagrożenie dla bezpieczeństwa">Zagrożenie dla bezpieczeństwa</option>
                            <option value="Naruszenie prywatności">Naruszenie prywatności</option>
                            <option value="Inne">Inne</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="reportDescription">Opis:</label>
                        <textarea
                            id="reportDescription"
                            value={reportDescription}
                            onChange={(e) => setReportDescription(e.target.value)}
                            required
                        />
                    </div>
                    <div className='post-page-report-buttons'>
                        <button type="submit" className="report">Zgłoś</button>
                        <button type="button" className="cancel" onClick={() => setIsModalOpen(false)}>Anuluj</button>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default PostPage;