// MiniProfil.js
import React from 'react';
import '../styles/MiniProfil.css'; // Dodaj odpowiednie style

const MiniProfil = ({ user }) => {
    console.log('MiniProfil user:', user);
    if (!user) return null;

    return (
        <div className="mini-profil">
            
            <div>
                <strong>{user.nick}</strong>
                
            </div>
        </div>
    );
};

export default MiniProfil;