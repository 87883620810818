import React, { useState } from 'react';
import { useUser  } from '../context/UserContext'; // Importuj kontekst użytkownika
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/CreatePost.css';

const AddPost = () => {
    const { user } = useUser (); // Uzyskaj informacje o użytkowniku
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Inicjalizuj useNavigate

    const handleAddPost = async (e) => {
        e.preventDefault();

        if (!user) {
            setError('Musisz być zalogowany, aby dodać post.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('image', image); // Upewnij się, że obraz jest dodawany
            formData.append('title', title);
            formData.append('content', content);
            formData.append('userId', user.uid);
            formData.append('nick', user.nick);

            await axios.post('https://gymsocial.pl:3001/api/posts', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setTitle('');
            setContent('');
            setImage(null);
            alert('Post dodany pomyślnie!');
            navigate('/home');
        } catch (err) {
            setError(err.message);
        }
    };
    const renderInputWithMentions = (input) => {
        const mentionRegex = /@(\w+)/g; // Wyrażenie regularne do wykrywania oznaczeń
        const parts = input.split(mentionRegex);

        return parts.map((part, index) => {
            if (index % 2 === 1) { // Co drugi element to oznaczenie
                return (
                    <p className='mention' key={index}>{'@' + part}</p>
                );
            }
            return part; // Zwróć pozostałe części tekstu
        });
    };

    const currentDate = new Date().toLocaleString();

    return (
        <div className="create-post">
            <h1>Dodaj Post</h1>
            <form onSubmit={handleAddPost}>
                <input
                    type="text"
                    placeholder="Tytuł"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
                <textarea
                    placeholder="Treść"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    required
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setImage(e.target.files[0])}
                />
                <button type="submit">Dodaj Post</button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </form>

            {/* Podgląd posta */}
            <div style={{ marginTop: '20px', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                <h2>Podgląd Posta</h2>
                <h3>{user.nick || 'Autor...'}</h3>
                <h4>{currentDate || 'Data...'}</h4>
                <h5>{title || 'Tytuł...'}</h5>
                {image && (
                    <img
                        src={URL.createObjectURL(image)}
                        alt="Podgląd"
                        style={{
                            maxWidth: '800px', // Ustaw maksymalną szerokość na 800px
                            maxHeight: '400px', // Ustaw maksymalną wysokość na 400px
                            height: 'auto', // Utrzymaj proporcje
                            borderRadius: '5px',
                            objectFit: 'cover', // Utrzymaj proporcje obrazu
                        }}
                    />
                )}
                <p>{renderInputWithMentions(content) || 'Treść...'}</p>
            </div>
        </div>
    );
};

export default AddPost;