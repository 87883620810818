import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser  } from '../context/UserContext';
import { Link, NavLink, useParams } from 'react-router-dom';
import '../styles/Messages.css'; // Upewnij się, że masz odpowiednie style
import { faUser  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Messages = () => {
    const { user } = useUser ();
    const [conversations, setConversations] = useState([]);
    const [otherConversations, setOtherConversations] = useState([]); // Nowy stan dla konwersacji "Inne"
    const [usersToMessage, setUsersToMessage] = useState([]);
    const [showOtherConversations, setShowOtherConversations] = useState(false); // Stan do zarządzania widocznością "Innych konwersacji"
    const { conversationId } = useParams();

    useEffect(() => {
        const fetchConversations = async () => {
            if (!user) return;
            try {
                const response = await axios.get(`https://gymsocial.pl:3001/api/messages/${user.uid}/conversations`);
                setConversations(response.data.followed); // Ustaw konwersacje od obserwowanych
                setOtherConversations(response.data.other); // Ustaw konwersacje od nieobserwowanych
            } catch (error) {
                console.error('Błąd podczas pobierania konwersacji:', error);
            }
        };

        fetchConversations();
    }, [user]);

    useEffect(() => {
        const fetchUsersToMessage = async () => {
            if (!user) return;
            try {
                const response = await axios.get(`https://gymsocial.pl:3001/api/users/${user.uid}/following`);
                // Filtruj użytkowników, aby wykluczyć tych, z którymi już masz konwersacje
                const filteredUsers = response.data.filter(userToMessage =>
                    !conversations.some(conversation =>
                        (conversation.user1Id === userToMessage.uid && conversation.user2Id === user.uid) ||
                        (conversation.user2Id === userToMessage.uid && conversation.user1Id === user.uid)
                    )
                );
                setUsersToMessage(filteredUsers);
            } catch (error) {
                console.error('Błąd podczas pobierania użytkowników:', error);
            }
        };

        fetchUsersToMessage();
    }, [user, conversations]);

    const handleCreateConversation = async (userId) => {
        if (!userId) return;

        const existingConversation = conversations.find(conversation =>
            (conversation.user1Id === userId && conversation.user2Id === user.uid) ||
            (conversation.user2Id === userId && conversation.user1Id === user.uid)
        );

        if (existingConversation) {
            alert('Już masz aktywną konwersację z tym użytkownikiem.');
            return;
        }

        try {
            const response = await axios.post('https://gymsocial.pl:3001/api/messages/new', {
                user1Id: user.uid,
                user2Id: userId,
            });
            const updatedConversations = await axios.get(`https://gymsocial.pl:3001/api/messages/${user.uid}/conversations`);
            setConversations(updatedConversations.data.followed);
            setOtherConversations(updatedConversations.data.other);
        } catch (error) {
            console.error('Błąd podczas tworzenia konwersacji:', error);
        }
    };



    const toggleOtherConversations = () => {
        setShowOtherConversations(prev => !prev);
    };

    return (
        <div className="messages">
            <h1>Wiadomości</h1>
            <button onClick={toggleOtherConversations} className="toggle-other-conversations">
                {showOtherConversations ? 'Pokaż główne konwersacje' : 'Inne konwersacje'}
            </button>
            {usersToMessage.length > 0 && (
                <div className="new-conversation">
                    <h2>Rozpocznij nową konwersację</h2>
                    <ul>
                        {usersToMessage.map(user => (
                            <li key={user.uid} onClick={() => handleCreateConversation(user.uid)} className="user-item">
                                {user.nick}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {!showOtherConversations && (
                <div className="conversation-list">
                <h2>Obserwowani</h2>
                    {conversations.length > 0 ? (
                        conversations.map(conversation => (
                            <Link key={conversation.id} to={`/messages/${conversation.id}`} className="conversation-item">
                                <div className="conversation-details">
                                    <div className='conversation-user-details'>
                                        {conversation.otherUserProfilePic ? (
                                            <img
                                                src={`https://gymsocial.pl:3001/profilePic/${conversation.otherUserProfilePic}`} 
                                                alt={`${conversation.otherUserNick}'s profile`} 
                                                className="profile-pic" 
                                            />
                                        ) : (
                                            <img
                                                src={`https://gymsocial.pl:3001/profilePic/d-p-f.png`}
                                                className="profile-pic" 
                                            />
                                        )}
                                        <h3>{conversation.otherUserNick}</h3>
                                    </div>
                                    <div className="conversation-info">
                                        <h4>
                                            {conversation.lastMessageSenderId === user.uid 
                                                ? 'Ty' 
                                                : conversation.lastMessageSenderNick
                                            }
                                        </h4>
                                        <p>{conversation.lastMessage}</p>
                                        <span>{new Date(conversation.lastMessageTime).toLocaleString()}</span>
                                    </div>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <p>Brak konwersacji. Rozpocznij nową rozmowę!</p>
                    )}
                </div>
            )}
            {showOtherConversations && (
                <div className="conversation-list">
                    <h2>Inne</h2>
                    {otherConversations.length > 0 ? (
                        otherConversations.map(conversation => (
                            <Link key={conversation.id} to={`/messages/${conversation.id}`} className="conversation-item">
                                <div className="conversation-details">
                                    <div className='conversation-user-details'>
                                        {conversation.otherUserProfilePic ? (
                                            <img 
                                                src={`https://gymsocial.pl:3001/profilePic/${conversation.otherUserProfilePic}`} 
                                                alt={`${conversation.otherUserNick}'s profile`} 
                                                className="profile-pic" 
                                            />
                                        ) : (
                                            <img
                                                src={`https://gymsocial.pl:3001/profilePic/d-p-f.png`}
                                                className="profile-pic" 
                                            />
                                        )}
                                        <h3>{conversation.otherUserNick}</h3>
                                    </div>
                                    <div className="conversation-info">
                                        <h4>
                                            {conversation.lastMessageSenderId === user.uid 
                                                ? 'Ty' 
                                                : conversation.lastMessageSenderNick
                                            }
                                        </h4>
                                        <p>{conversation.lastMessage}</p>
                                        <span>{new Date(conversation.lastMessageTime).toLocaleString()}</span>
                                    </div>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <p>Brak konwersacji. Rozpocznij nową rozmowę!</p>
                    )}
                </div>
            )}
        </div>
    );
};


export default Messages;