import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser  } from '../context/UserContext';
import { Link } from 'react-router-dom';
import '../styles/NotificationsPage.css';

const NotificationsPage = () => {
    const { user } = useUser ();
    const [newNotifications, setNewNotifications] = useState([]);
    const [oldNotifications, setOldNotifications] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`https://gymsocial.pl:3001/api/notifications/${user.uid}`);
                setNewNotifications(response.data.newNotifications);
                setOldNotifications(response.data.oldNotifications);
            } catch (error) {
                console.error('Błąd podczas pobierania powiadomień:', error);
            }
        };

        if (user) {
            fetchNotifications();
        }
    }, [user]);

    const handleNotificationClick = async (notificationId, conversationId) => {
        try {
            // Oznacz powiadomienie jako przeczytane
            await axios.put(`https://gymsocial.pl:3001/api/notifications/${notificationId}/read`);
            
            // Zaktualizuj stan, aby usunąć powiadomienie z nowych
            setNewNotifications(prev => prev.filter(notification => notification.id !== notificationId));
            
            // Dodaj powiadomienie do starych
            const clickedNotification = newNotifications.find(notification => notification.id === notificationId);
            if (clickedNotification) {
                setOldNotifications(prev => [...prev, clickedNotification]);
            }
            console.log('cid', conversationId)
            // Sprawdź, czy powiadomienie dotyczy wiadomości
            if (conversationId) {
                // Przenieś użytkownika do konwersacji
                window.location.href = `/messages/${conversationId}`; // Zmień na odpowiednią trasę
            } else {
                // W przeciwnym razie przenieś do profilu
                window.location.href = `/profile/${clickedNotification.senderId}`;
            }
        } catch (error) {
            console.error('Błąd podczas oznaczania powiadomienia jako przeczytane:', error);
        }
    };

    return (
        <div className="notifications-page">
            <h1>Powiadomienia</h1>
            <h2>Nowe powiadomienia</h2>
            {newNotifications.length === 0 ? (
                <p>Brak nowych powiadomień.</p>
            ) : (
                <ul className="notification-list">
                    {newNotifications.map(notification => (
                        <li key={notification.id} onClick={() => handleNotificationClick(notification.id, notification.conversationId)} className="notification-item">
                            <Link to={`/profile/${notification.senderId}`} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                                {notification.senderProfilePic && (
                                    <img
                                        src={`https://gymsocial.pl:3001/profilePic/${notification.senderProfilePic}`}
                                        alt={`${notification.senderNick}'s profile`}
                                        style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                                    />
                                )}
                                <strong>{notification.message}</strong>
                                <span style={{ float: 'right', color: '#888', marginLeft: 'auto' }}>
                                    {new Date(notification.createdAt).toLocaleString()}
                                </span>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
            <h2>Stare powiadomienia</h2>
            {oldNotifications.length === 0 ? (
                <p>Brak starych powiadomień.</p>
            ) : (
                <ul className="notification-list">
                    {oldNotifications.map(notification => (
                        <li key={notification.id} className="notification-item">
                            <Link to={`/profile/${notification.senderId}`} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                                {notification.senderProfilePic && (
                                    <img
                                        src={`https://gymsocial.pl:3001/profilePic/${notification.senderProfilePic}`}
                                        alt={`${notification.senderNick}'s profile`}
                                        style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                                    />
                                )}
                                <strong>{notification.message}</strong>
                                <span style={{ float: 'right', color: '#888', marginLeft: 'auto' }}>
                                    {new Date(notification .createdAt).toLocaleString()}
                                </span>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default NotificationsPage;