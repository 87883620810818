import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useUser  } from '../context/UserContext'; // Importuj kontekst użytkownika
import '../styles/ProfilePage.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser  } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { handleBlockUser } from './inne/block';
import timeAgo from './inne/timeAgo'
import Modal from 'react-modal'

const ProfilePage = () => {
    const { uid } = useParams();
    const { user: currentUser  } = useUser (); // Uzyskaj aktualnego użytkownika
    const userId = currentUser  ? currentUser .uid : null; // Ustaw userId na uid aktualnego użytkownika
    const [userData, setUserData] = useState(null);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isFollowed, setIsFollowed] = useState(false); // Dodaj stan dla isFollowed
    const [followersCount, setFollowersCount] = useState(0); // Stan dla liczby obserwujących
    const postRefs = useRef([]);
    const [followingCount, setFollowingCount] = useState(0); // Stan dla liczby obserwowanych
    const [isReportFormVisible, setIsReportFormVisible] = useState(false);
    const [reportDescription, setReportDescription] = useState('');
    const [reportCategory, setReportCategory] = useState('');
    const [followersList, setFollowersList] = useState([]);
    const [isFollowersModalOpen, setIsFollowersModalOpen] = useState(false);
    const [followingList, setFollowingList] = useState([]);
    const [isFollowingModalOpen, setIsFollowingModalOpen] = useState(false);

    // Fetch user data and posts
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://gymsocial.pl:3001/api/users/${uid}`);
                setUserData(response.data);
                const postsResponse = await axios.get(`https://gymsocial.pl:3001/api/users/${uid}/posts`);
                setPosts(postsResponse.data);
            } catch (error) {
                setError('Błąd podczas ładowania danych użytkownika.');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [uid]);

    // Check if the current user is following the profile user
    useEffect(() => {
        if (userId) {
            const checkIfFollowed = async () => {
                try {
                    const response = await axios.get(`https://gymsocial.pl:3001/api/users/${uid}/followed`, {
                        params: { userId }
                    });
                    setIsFollowed(response.data.followed);
                } catch (error) {
                    console.error('Błąd podczas sprawdzania obserwacji:', error);
                }
            };

            checkIfFollowed();
        }
    }, [userId, uid]); // Dodaj userId i uid jako zależności
    const handleReportProfile = async () => {
        try {
            await axios.post(`https://gymsocial.pl:3001/api/users/${uid}/report`, {
                reporterId: userId,
                description: reportDescription,
                category: reportCategory,
            });
            alert('Profil został zgłoszony.');
            setReportDescription('');
            setReportCategory('');
            setIsReportFormVisible(false); // Ukryj formularz po zgłoszeniu
        } catch (error) {
            console.error('Błąd podczas zgłaszania profilu:', error);
            alert('Wystąpił błąd podczas zgłaszania profilu.');
        }
    };
    const fetchFollowersList = async () => {
        try {
            const response = await axios.get(`https://gymsocial.pl:3001/api/users/${uid}/followers`);
            setFollowersList(response.data); // Zakładam, że odpowiedź zawiera listę użytkowników
        } catch (error) {
            console.error('Błąd podczas pobierania listy obserwujących:', error);
        }
    };
    const fetchFollowingList = async () => {
        try {
            const response = await axios.get(`https://gymsocial.pl:3001/api/users/${uid}/following`);
            setFollowingList(response.data); // Zakładam, że odpowiedź zawiera listę użytkowników
        } catch (error) {
            console.error('Błąd podczas pobierania listy obserwowanych:', error);
        }
    };
    // Fetch followers count
    useEffect(() => {
        const fetchFollowersCount = async () => {
            try {
                const response = await axios.get(`https://gymsocial.pl:3001/api/users/${uid}/followers/count`);
                setFollowersCount(response.data.followersCount);
            } catch (error) {
                console.error('Błąd podczas ładowania liczby obserwujących:', error);
            }
        };
    
        const fetchFollowingCount = async () => {
            try {
                const response = await axios.get(`https://gymsocial.pl:3001/api/users/${uid}/following/count`);
                setFollowingCount(response.data.followingCount);
            } catch (error) {
                console.error('Błąd podczas ładowania liczby obserwowanych:', error);
            }
        };
    
        fetchFollowersCount();
        fetchFollowingCount();
    }, [uid]);

    if (loading) return <div>Ładowanie...</div>;
    if (error) return <div>{error}</div>;

    const handleFollow = async () => {
        try {
            await axios.post(`https://gymsocial.pl:3001/api/users/${uid}/follow`, { userId });
            setIsFollowed(true);
            setFollowersCount(prevCount => prevCount + 1); // Zwiększ liczbę obserwujących
            alert('Obserwujesz tego użytkownika!');
        } catch (error) {
            console.error('Błąd podczas obserwowania użytkownika:', error);
        }
    };

    const handleUnfollow = async () => {
        try {
            await axios.delete(`https://gymsocial.pl:3001/api/users/${uid}/follow`, { data: { userId } });
            setIsFollowed(false);
            setFollowersCount(prevCount => prevCount - 1); // Zmniejsz liczbę obserwujących
            alert('Przestałeś obserwować tego użytkownika.');
        } catch (error) {
            console.error('Błąd podczas przestawania obserwowania użytkownika:', error);
        }
    };

    const blockUser  = () => {
        handleBlockUser (uid, userId); // Pass uid and userId as arguments
    };
    return (
        <div className="profile-page">
            <div className="profile-header">
                {userData.profilePic ? (
                    <img src={`https://gymsocial.pl:3001/profilePic/${userData.profilePic}`} alt={`${userData.nick}'s profile`} className="profile-pic" />
                ) : (
                    <img
                        src={`https://gymsocial.pl:3001/profilePic/d-p-f.png`}
                        className="profile-pic" />
                )}
                <h1>{userData.nick}</h1>
                <p onClick={() => { fetchFollowersList(); setIsFollowersModalOpen(true); }} style={{ cursor: 'pointer' }}>
                    Liczba obserwujących: {followersCount}
                </p>
                <p onClick={() => { fetchFollowingList(); setIsFollowingModalOpen(true); }} style={{ cursor: 'pointer' }}>
                    Liczba obserwowanych: {followingCount}
                </p>
                {isFollowed ? (
                    <button onClick={handleUnfollow}>Przestań obserwować</button>
                ) : (
                    <button onClick={handleFollow}>Obserwuj</button>
                )}
                <button onClick={() => setIsReportFormVisible(!isReportFormVisible)}>
                    {isReportFormVisible ? 'Anuluj zgłoszenie' : 'Zgłoś profil'}
                </button>
                <button onClick={blockUser }>Zablokuj użytkownika</button>
                {isReportFormVisible && (
                    <div>
                        <h3>Zgłoś profil</h3>
                        <textarea
                            placeholder="Opisz powód zgłoszenia..."
                            value={reportDescription}
                            onChange={(e) => setReportDescription(e.target.value)}
                        />
                        <select value={reportCategory} onChange={(e) => setReportCategory(e.target.value)}>
                            <option value="">Wybierz kategorię</option>
                            <option value="spam"> Spam</option>
                            <option value="niewłaściwe zachowanie">Niewłaściwe zachowanie</option>
                            <option value="oszustwo">Oszustwo</option>
                        </select>
                        <button onClick={handleReportProfile}>Zgłoś</button>
                    </div>
                )}
            </div>
            <div className="post-list">
                {posts.length > 0 ? (
                    posts.map((post, index) => (
                        <div className="post" key={index}>
                            <div>
                                <div className="post-content-over">
                                    <div className="post-content" ref={el => (postRefs.current[index] = el)}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {post.profilePic && (
                                                <img
                                                    src={`https://gymsocial.pl:3001/profilePic/${post.profilePic}`}
                                                    alt={`${post.nick}'s profile`}
                                                    style={{
                                                        width: '40px',
                                                        height: '40px',
                                                        borderRadius: '50%',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                            )}
                                            <Link to={`/profile/${post.userId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                <strong>{post.nick}</strong>
                                            </Link>
                                            <em style={{ marginLeft: '10px', fontSize: '0.9em', color: '#888' }}>
                                                {timeAgo(post.createdAt)}
                                            </em>
                                        </div>
                                        <h2>{post.title}</h2>
                                        {post.imageUrl && (
                                            <img
                                                src={`https://gymsocial.pl:3001/postsPic/${post.imageUrl}`}
                                                alt={post.title}
                                            />
                                        )}
                                        <p>{post.content}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Brak postów użytkownika.</p>
                )}
            </div>
            <Modal
                isOpen={isFollowingModalOpen}
                onRequestClose={() => setIsFollowingModalOpen(false)}
                contentLabel="Obserwowani"
                className="modal"
                overlayClassName="overlay"
            >
                <h2>Obserwowani</h2>
                <ul>
                    {followingList.map(following => (
                        <li key={following.uid}>{following.nick}</li> // Zakładając, że każdy użytkownik ma uid i nick
                    ))}
                </ul>
                <button onClick={() => setIsFollowingModalOpen(false)}>Zamknij</button>
            </Modal>
            <Modal
                isOpen={isFollowersModalOpen}
                onRequestClose={() => setIsFollowersModalOpen(false)}
                contentLabel="Obserwujący"
                className="modal"
                overlayClassName="overlay"
            >
                <h2>Obserwujący</h2>
                <ul>
                    {followersList.map(follower => (
                        <li key={follower.id}>{follower.nick}</li> // Zakładając, że każdy użytkownik ma id i nick
                    ))}
                </ul>
                <button onClick={() => setIsFollowersModalOpen(false)}>Zamknij</button>
            </Modal>
        </div>
    );
};

export default ProfilePage;